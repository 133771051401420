/*
  SITEMAP PAGE TEMPLATE
  A template to display sitemap pages
*/

import React from 'react'
import { graphql, Link } from 'gatsby'

import PostContextProvider from '@base/utils/contexts/posts-context'
import PageLayout from '@base/layout/page-layout'
import { localizePath } from '@base/utils/helpers/localization/localize'

import Loader from '@elements/loader'
import Layout from '@layout/layout'
import { getGeneralData } from '@api/general-data'
import { getContextData } from '@api/context-data'
import { getAllData } from '@api/all-data'

const SitemapPage = ({ pageContext, data }) => {
  const dataStatic = {
    pageData: {
      title: 'Sitemap',
      localizations: null,
    },
  }

  // Setup Global website data (data that will be used in layout components like header and footer)
  const contextData = getContextData(pageContext, dataStatic)
  const allGeneralData = getAllData(pageContext.globalData)

  // Setup Page specific data
  const generalData = getGeneralData(pageContext, dataStatic)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const title = dataStatic.pageData.title
  const parentPage = null
  const disablePageBreadcrumbs = true

  return (
    <Layout
      data={dataStatic ? dataStatic.pageData : ''}
      globalData={allGeneralData}
      pageContext={contextData}
    >
      <PageLayout
        pageContext={minifiedPageContext}
        modalData={modalData}
        breadcrumbs={{
          enableGlobalBreadcrumbs: true,
          disablePageBreadcrumbs: disablePageBreadcrumbs,
          postTitle: title,
          postParent: [parentPage],
          strings: {
            back: globalData.stringsActions.back,
            home: globalData.stringsSlugs.homeTitle,
          },
        }}
      >
        <PostContextProvider>
          <section className="container flex flex-col items-center pt-12 lg:pt-32 mb-96">
            <h1>{pageContext.content.title}</h1>
            <div className="flex w-full flex-wrap py-12 text-white lg:py-24">
              {data.allStrapiPage.edges.map((page) => (
                <div className="w-full p-4 text-center md:w-1/3 md:text-left xl:w-1/4">
                  <Link
                    className="text-md font-body transition-all duration-300 hover:text-secondary normal-case"
                    to={page.node.slug ? `../${page.node.slug}` : '../'}
                  >
                    {page.node.title}
                  </Link>
                </div>
              ))}
            </div>
            <h2>{pageContext.content.news}</h2>
            <div className="flex w-full flex-wrap pt-12 text-white lg:pt-24">
              {data.allStrapiArticle.edges.map((article) => (
                <div className="w-full p-4 text-center md:w-1/2 md:text-left">
                  <Link
                    to={localizePath({ locale: article.node.locale, slug: `${globalData.stringsSlugs.article}/${article.node.slug}` })}
                    className="text-md font-body transition-all duration-300 hover:text-secondary normal-case"
                  >
                    {article.node.title}
                  </Link>
                </div>
              ))}
            </div>
          </section>
        </PostContextProvider>
      </PageLayout>
      <Loader pageContext={contextData} />
    </Layout>
  )
}

export default SitemapPage

export const query = graphql`
  query SitemapPageQuery($locale: String!) {
    allStrapiPage(
      filter: { locale: { eq: $locale }, publishedAt: { ne: null } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          slug
          locale
          title
        }
      }
    }
    allStrapiArticle(
      filter: { locale: { eq: $locale }, publishedAt: { ne: null } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          locale
          slug
          title
        }
      }
    }
  }
`

export { Head } from '@base/layout/head'
